
import { useAuth0 } from "@auth0/auth0-vue"

export default function checkRoles(rolesList) {
    if(useAuth0().isAuthenticated) {
        if (useAuth0().idTokenClaims.value) {
            const userRoles = useAuth0().idTokenClaims.value[`/roles`]
            return userRoles.includes('Admin') || userRoles.some(role => rolesList.includes(role))
        }
    }else{
        return false
    }
}




