import VueJwtDecode from "vue-jwt-decode";
import { useAuth0 } from "@auth0/auth0-vue"
import {ref} from 'vue'

const permissions = ref([])

// Function to fetch permissions
async function fetchPermissions() {
    try{
        const token = await useAuth0().getAccessTokenSilently()
        permissions.value = VueJwtDecode.decode(token).permissions

    } catch(error){
        permissions.value = []
    }
}

const PermissionsModule = {
    permissions,

   hasPermission(permission){
       if (Array.isArray(permission)) {
           for (let i = 0; i < permission.length; i++) {
               const found = permissions.value.includes(permission[i]);
               if(found){
                   return true
               }
           }
       } else if (typeof permission === 'string') {
           return permissions.value.includes(permission)
       } else {
           return false
       }
    },

    async updatePermissions() {
        await fetchPermissions();
    }
};

export default PermissionsModule;




