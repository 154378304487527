<script setup>
import { useAuth0 } from "@auth0/auth0-vue";
import {onMounted} from "vue";
import callApi from "@/Javascript/callAPI";

import checkRoles from "@/Javascript/CheckRoles";
import PermissionsModule from "@/Javascript/PermissionsModule";


const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();

function login() {
  loginWithRedirect();
}
function logoutFunction() {
  logout({ returnTo: window.location.origin });
}


callApi().setToken().catch(()=>{})
// eslint-disable-next-line no-empty


// if(isAuthenticated.value){
//
// }

onMounted(()=>{
  PermissionsModule.updatePermissions()

})


// command to run project: npm run serve
</script>

<template>

  <nav class="navbar navbar-expand-lg navbar-light bg-light sticky-top" id="nav">
    <div class="container-fluid">
    <a class="navbar-brand" href="/">OE Alternatives</a>

    <div v-if="isAuthenticated">
      <router-link v-if="checkRoles(['OE Alternatives Employee'])" to="/Dashboard" class="nav-link">{{ user.name }}</router-link>
      <router-link v-else to="/" class="nav-link">{{ user.name }}</router-link>
    </div>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse " id="navbarSupportedContent" style="flex-grow: 0">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item dropdown">
          <div class="nav-link dropdown-toggle" id="EmployeeDropdown" role="button" data-bs-toggle="dropdown"
               aria-expanded="false" v-if="checkRoles(['OE Alternatives Employee'])">
            Employee Resources
          </div>
          <ul class="dropdown-menu" aria-labelledby="EmployeeDropdown" v-if="checkRoles(['OE Alternatives Employee'])">
<!--              <li class="dropdown-item d-flex justify-content-end">-->
            <li class="dropdown-item">
              <router-link to="/Jobs" class="nav-link">Jobs</router-link>
            </li>
            <li class="dropdown-item">
              <router-link to="/Customers" class="nav-link">Customers</router-link>
            </li>
            <li class="dropdown-item">
              <router-link to="/Employees" class="nav-link">Employees</router-link>
            </li>
            <li class="dropdown-item">
              <router-link to="/Calls" class="nav-link">Calls</router-link>
            </li>
            <li class="dropdown-item">
              <router-link to="/Advertising" class="nav-link">Advertising Attempts</router-link>
            </li>
            <li class="dropdown-item">
              <router-link to="/Notes" class="nav-link">Notes</router-link>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <div class="nav-link dropdown-toggle" id="EmployeeDropdown" role="button" data-bs-toggle="dropdown"
               aria-expanded="false" v-if="checkRoles(['OE Alternatives Employee', 'In Network'])">
            Utility Resources
          </div>

          <ul class="dropdown-menu" aria-labelledby="EmployeeDropdown" >
<!--            <li class="dropdown-item" v-if="PermissionsModule.hasPermission('download:utilities')">-->
<!--              <router-link to="/Utilities" class="nav-link">Utility Downloads</router-link>-->
<!--            </li>-->
            <li class="dropdown-item" v-if="PermissionsModule.hasPermission('edit:cvt')">
              <router-link to="/CVT/Create" class="nav-link">Create Nissan CVT File</router-link>
            </li>
            <li class="dropdown-item" v-if="PermissionsModule.hasPermission('edit:cvt')">
              <router-link to="/CVT/Download" class="nav-link">View Nissan CVT Files</router-link>
            </li>
          </ul>
        </li>


        <li class="nav-item" v-if="isAuthenticated">
          <router-link to="/Resources" class="nav-link">User Resources</router-link>
        </li>

        <li class="nav-item dropdown" v-if="PermissionsModule.hasPermission('admin')">
          <div class="nav-link dropdown-toggle" id="adminDropdown" role="button" data-bs-toggle="dropdown"
               aria-expanded="false">
            Admin
          </div>
          <ul class="dropdown-menu" aria-labelledby="adminDropdown">
            <li>
              <router-link class="dropdown-item" to="/Users">Users</router-link>
            </li>
            <li>
              <router-link class="dropdown-item" to="/Messages">Utility Messages</router-link>
            </li>
          </ul>
        </li>



        <li class="nav-item" v-if="PermissionsModule.hasPermission('testing')">
          <router-link to="/TestPage" class="nav-link">Test Page</router-link>
        </li>
      </ul>
      <div class="nav-item ms-2" v-if="!isAuthenticated">
        <button class="btn btn-sm btn-secondary" @click="login">Log in</button>
      </div>
      <div class="nav-item ms-2" v-if="isAuthenticated">
        <button class="btn btn-sm btn-secondary" @click="logoutFunction">Log out</button>
      </div>
    </div>
  </div>
  </nav>
  <div>
    <router-view />
  </div>

</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
@import '~bootstrap-icons/font/bootstrap-icons.css';

.main-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  /*padding: 0px 5px 0px 5px;*/
}

.header,
.footer {
  flex: 0 0 auto;
  margin-bottom: 1.5rem ;
  margin-top: 1.5rem;
}

.flexible-middle {
  flex: 1 1;
  overflow-y: auto;
}

.clear-translate{
  translate: -35px;
  padding: 0px;
}
</style>


