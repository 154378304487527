import { createApp } from 'vue'
import App from './App.vue'
import {createAuth0} from "@auth0/auth0-vue";
import router from './router'
import VueJwtDecode from 'vue-jwt-decode'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'





// createApp(App).mount('#app')
const app = createApp(App)

const auth0 = createAuth0({
    domain: process.env.VUE_APP_DOMAIN,
    client_id: process.env.VUE_APP_CLIENT_ID,
    redirect_uri: window.location.origin,
    audience: process.env.VUE_APP_AUDIENCE,
})

// import callAPI from './Javascript/callAPI'
//
// const {apiGet, apiPut} = callAPI()
app
    .use(router)
    .use(auth0)
    .mount('#app');


router.beforeEach(async(to,from)=>{
    const {permission} = to.meta;
    if(permission){
        const token = await auth0.getAccessTokenSilently().catch((error)=>{
            console.log(error)
            return auth0.loginWithRedirect()
        })
        const userPermissions = VueJwtDecode.decode(token).permissions
        if(!userPermissions.includes(permission)) {
            return from.path
        }
    }
})
